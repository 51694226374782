<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Dados Cadastrais (Indiretas)</template>
        <template #content>
          <div v-if="matriculasList.length > 1" class="p-fluid grid mb-1">
            <div class="field col-12 md:col-6">
              <Dropdown
                v-model="matricula"
                :options="matriculasList"
                data-key="id"
                placeholder="Matrícula"
                style="width: 100%">
                <template #value="slotProps">
                  <div v-if="slotProps.value">
                    <span>
                      {{ slotProps.value.matricula }} -
                      {{ slotProps.value.entidadeNome }}
                    </span>
                  </div>
                </template>
                <template #option="slotProps">
                  <span class="card-desktop">
                    {{ slotProps.option.matricula }} -
                    {{ slotProps.option.entidadeNome }}
                  </span>
                  <span class="card-mobile">
                    {{ slotProps.option.matricula }} -
                    {{ slotProps.option.entidadeNome | limitadorString }}
                  </span>
                </template>
              </Dropdown>
            </div>
          </div>

          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-12">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.nome"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Nome Completo</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="servidor.dataNascimento | formatarData"
                  class="input-text"
                  disabled />
                <label for="nome">Data de Nascimento</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="matricula.cpf | mascaraCpf"
                  class="input-text"
                  disabled />
                <label for="nome">CPF</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="servidor.sexo | formatarSexo"
                  class="input-text"
                  disabled />
                <label for="nome">Sexo</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="servidor.estadoCivilId | formatarEstadoCivil"
                  class="input-text"
                  disabled />
                <label for="nome">Estado Civil</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="servidor.rgNumero"
                  class="input-text"
                  disabled />
                <label for="nome">Identidade</label>
              </span>
            </div>
            <div class="field col-6 md:col-1">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="servidor.rgUf"
                  class="input-text"
                  disabled />
                <label for="nome">UF</label>
              </span>
            </div>
          </div>

          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="matricula.entidadeNome"
                  class="input-text"
                  disabled />
                <label for="cpf">Entidade</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="sexo"
                  type="text"
                  :value="matricula.matricula"
                  class="input-text"
                  disabled />
                <label for="sexo">Matricula</label>
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="raca"
                  v-model="matricula.vinculoNome"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="raca">Vinculo</label>
              </span>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="servidor.celular"
                  class="input-text"
                  disabled />
                <label for="cpf">Celular</label>
              </span>
            </div>
            <div class="field col-12 md:col-5">
              <span class="p-float-label">
                <InputText
                  id="sexo"
                  type="text"
                  :value="servidor.email"
                  class="input-text"
                  disabled />
                <label for="sexo">E-Mail</label>
              </span>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import MatriculaService from '@/service/MatriculaService.js'
import { servidorStore } from '@/stores/servidor'

export default {
  setup() {
    const store = servidorStore()
    return { store, v$: UseVuelidate() }
  },
  data() {
    return {
      matriculasList: [],
      matricula: {},
      loading: false,
      servidor: {},
    }
  },

  created() {
    this.matriculaService = new MatriculaService(this.$http)
  },

  mounted() {
    this.carregarDados()
  },

  methods: {
    carregarDados() {
      this.loading = true
      this.matriculaService
        .getMatriculasUsuarioLogado()
        .then((res) => {
          this.matriculasList = res.filter(
            (item) =>
              !item.entidadeNome.includes('SEAD 1') &&
              !item.entidadeNome.includes('SEAD 2'),
          )
          this.matricula = this.matriculasList[0]
          this.getServidor(this.matricula.id)
          this.limpar()
        })
        .catch((err) => {
          const mensagem = err.response
            ? err.response.data.message
            : 'Erro de conexão com a aplicação.'
          this.limpar()
          this.$toast.add({
            severity: 'error',
            summary: mensagem,
            life: 10000,
          })
          this.$router.push('/logout')
        })
    },

    limpar() {
      this.loading = false
    },
    getServidor(matricula) {
      this.matriculaService
        .getMatriculaServidorLogado(matricula)
        .then((res) => {
          this.servidor = res.servidor
        })
    },
  },
}
</script>

<style>
.input-text {
  width: 100%;
  color: black;
}
</style>
